<template>
  <ui-modal
    v-bind="$attrs"
    :header="location && location.is_geofence ? $t('modal.location_details.zone_title') : $t('modal.location_details.title')"
    :open="isOpen"
    closable
    @close="() => $emit('close-modal')"
  >
    <div slot="body">
      <ui-loader
        v-if="loading"
        class="pt-5 center"
      />
      <div class="Location__slide ml-n4 mr-n4">
        <Swipe :swipe="swipe">
          <template v-for="(img, index) in getValue(location, 'images', [])">
            <div
              :key="index"
              class="rd-swipe-item"
            >
              <img
                :src="url(img)"
                alt=""
                class="img-fluid"
              >
            </div>
          </template>
        </Swipe>
      </div>
      <br>
      <h6 class="Location__header emobg-color-ink-light">
        {{ printableAddress(location) }}
      </h6>
      <p class="Location__text">
        {{ getValue(location, 'address', null) }}
      </p>
      <p
        v-if="getValue(location,'is_geofence', false)"
        v-html="$t('modal.location_details.zone_info')"
      />
      <br>

      <div v-html="compiledDescription(getValue(location, 'description', ''))" />
    </div>
  </ui-modal>
</template>

<script>
// Utils
import { getValue } from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';

import { printableAddress } from '@/helpers/booking/locationHelpers';
// Components
// Mixins
import BookingTypeMixin from '@/mixins/BookingType';

// Constants
import {
  SEGMENT_ENUMERATE_VALUES,
  SEGMENT_EVENTS,
  SEGMENT_PARAM_NAMES,
} from '@/vue/constants';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useSegment } from '@/composable/Segment/segment';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

// External packages
const Marked = require('marked');
const Swipe = () => import('vue-slide/vue-slide');

export default {
  name: 'LocationDetailsModal',
  components: {
    Swipe,
  },
  mixins: [
    BookingTypeMixin,
  ],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    parentLocation: {
      type: Object,
      default: () => ({}),
    },
    mapManager: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const { trackPageView } = useTrackingGTM();
    const { trackSegment } = useSegment();
    const { notifyError } = useNotifications();
    return { trackPageView, trackSegment, notifyError };
  },

  data() {
    return {
      location: null,
      loading: true,
      swipe: {
        activeIndex: 0,
        autoplay: 10 ** 20,
      },
    };
  },

  computed: {
    isLongDistance() {
      return this.currentBookingType.code === BOOKING_TYPES.longDistance;
    },
  },
  async created() {
    try {
      const data = await external.fleetLocations.getLocationDetails(this.parentLocation.uuid);
      this.location = { ...this.parentLocation, ...data };

      this.loading = false;
      this.detailedLocationShowed();
    } catch (error) {
      // Exception manager
      this.notifyError({
        text: error.message,
        textAction: 'Ok',
      });
      this.$emit('close-modal');
      this.trackPageView();
    }
  },

  methods: {
    getValue,
    printableAddress,
    compiledDescription(description = '') {
      return Marked(description, { sanitize: true, breaks: true });
    },
    detailedLocationShowed() {
      this.trackSegment({
        name: SEGMENT_EVENTS.LOCATION_DETAILS_VIEWED,
        data: {
          [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: this.isLongDistance
            ? SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.LONG_DISTANCE
            : SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.CAR_SHARING,
        },
      });
    },
    url(src) {
      return src.replace(/%7C/g, '|');
    },
  },
};

</script>
