<template>
  <article class="Vehicle Vehicle__bookingSummary justify-content-start">
    <!-- Image about vehicle -->
    <figure class="Vehicle_imageWrapper">
      <img
        :src="vehicle.image"
        alt="Vehicle image"
        width="175px"
        class="Vehicle_image mb-2"
      >
    </figure>

    <!-- Info about booking -->
    <div class="Vehicle_dataWrapper">
      <!-- Info about vehicle -->
      <ModelComponent
        :is-electric="isElectric"
        :model="vehicle.model"
      />

      <LicensePlateComponent
        v-if="vehicle.license_plate"
        class="my-1 emobg-color-ink"
      >
        {{ vehicle.license_plate }}
      </LicensePlateComponent>

      <!-- Info about battery (in case car is electric) -->
      <div class="d-flex align-items-center my-1">
        <BatteryInfoComponent
          v-if="isElectric"
          :critical-level="get(vehicle, 'electric_details.low_battery_level')"
          :current-level="get(vehicle, 'electric_details.power_battery_level')"
          class="mr-1 mb-0"
        />
        <ChargeBatteryTooltipComponent
          v-if="isElectric"
          v-bind="chargeBatteryProps"
        />
      </div>
    </div>

    <div class="w-100">
      <ChargeBatteryAlertComponent
        v-if="isElectric"
        v-bind="chargeBatteryProps"
      />
    </div>

    <!-- Extra info about the vehicle and his tariff -->
    <div class="Vehicle_details mt-4 mb-3">
      <!-- Vehicle details -->
      <SpecificationsComponent
        :class="{
          'mb-4': vehicle.equipment || (vehicle.tariff && !isUnpricedCompany)
        }"
        :category="vehicle.category"
        :fuel="vehicle.fuel"
        :is-electric="isElectric"
        :license-plate="vehicle.license_plate"
        :seats="vehicle.seats"
        :transmission="vehicle.transmission"
      />

      <!-- More specs -->
      <ExtrasComponent
        :extras="vehicle.equipment"
        :class="{
          'mb-4': vehicle.tariff && !isUnpricedCompany,
        }"
      />

      <!-- Pricing information -->
      <TariffInformationComponent
        v-if="vehicle.tariff && !isUnpricedCompany"
        :tariff="vehicle.tariff"
        :is-outside-working-hours="isOutsideWorkingHours"
        :is-electric="isElectric"
      />
    </div>
  </article>
</template>

<script>
import get from 'lodash/get';
import { DISTANCE_UNIT } from '@emobg/web-utils';
import LicensePlateComponent from '@/components/VehicleCard/components/LicensePlate/LicensePlateComponent';
import BatteryInfoComponent from '@/components/VehicleCard/components/BatteryInfo/BatteryInfoComponent';
import TariffInformationComponent from '@/components/VehicleCard/components/TariffInformation/TariffInformationComponent';
import SpecificationsComponent from '@/components/VehicleCard/components/Specifications/SpecificationsComponent';
import ModelComponent from '@/components/VehicleCard/components/Model/ModelComponent';
import ExtrasComponent from '@/components/VehicleCard/components/Extras/ExtrasComponent';
import ChargeBatteryAlertComponent from '@/components/VehicleCard/components/ChargeBatteryAlert/ChargeBatteryAlertComponent';
import ChargeBatteryTooltipComponent from '@/components/VehicleCard/components/ChargeBatteryTooltip/ChargeBatteryTooltipComponent';

export default {
  name: 'VehicleBookingSummary',
  components: {
    BatteryInfoComponent,
    ChargeBatteryAlertComponent,
    ChargeBatteryTooltipComponent,
    ExtrasComponent,
    LicensePlateComponent,
    ModelComponent,
    SpecificationsComponent,
    TariffInformationComponent,
  },

  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    /**
       * @param { start, end } as a mysql date format
       */
    booking: {
      type: Object,
      required: true,
    },

    /**
       * @param { name, address }
       */
    location: {
      type: Object,
      required: true,
    },

    operator: {
      type: Object,
      default: () => ({}),
    },

    isOutsideWorkingHours: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUnpricedCompany() {
      // When a company is unpriced, we should not show
      // any type of price. By default, if this $prop
      // does not exist, we will show the price.
      return get(this, 'vehicle.tariff.is_unpriced_tariff');
    },

    mileageUnit() {
      return get(this.operator, 'configuration.mileage_unit', DISTANCE_UNIT.kilometers);
    },

    isElectric() {
      return !!get(this, 'vehicle.electric_details');
    },

    chargeBatteryProps() {
      return {
        bookingStart: this.booking.start,
        electricDetails: this.vehicle.electric_details,
        isBeingUsed: this.vehicle.is_being_used,
        operator: this.operator,
      };
    },
  },
  methods: { get },
};

</script>
